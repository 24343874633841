import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'

import bus from "@/plugins/bus";
export default {
  name: "rideCardAdd",
  mixins: [mixins],
  data() {
    return {
      edit: false,
      formModel: {
        id: "",
        name: "",
        beginTime: "",
        endTime: "",
        effectiveDay: "",
        effectiveCount: "",
        originalPrice: "",
        nowPrice: "",
        deductionFee: ""
      },
      formRules: {
        name: [{
          required: true,
          message: '请输入骑行卡',
          trigger: 'blur'
        }],
        effectiveDay: [{
          required: true,
          message: '请输入有效时长',
          trigger: 'blur'
        }],
        effectiveCount: [{
          required: true,
          message: '请输入次数',
          trigger: 'blur'
        }],
        originalPrice: [{
          required: true,
          message: '请输入原价',
          trigger: 'blur'
        }],
        nowPrice: [{
          required: true,
          message: '请输入现价',
          trigger: 'blur'
        }],
        deductionFee: [{
          required: true,
          message: '请输入每单抵扣额',
          trigger: 'blur'
        }],
        beginTime: [{
          required: true,
          message: '请输入开始时间',
          trigger: 'blur'
        }],
        endTime: [{
          required: true,
          message: '请输入结束',
          trigger: 'blur'
        }]
      }
    };
  },
  methods: {
    submitForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const callBack = res => {
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.$router.back();
              if (this.$route.meta && this.$route.meta.parent) {
                bus.emit(this.$route.meta.parent, true);
              }
            } else {
              ElMessage.error(res.message);
            }
          };
          if (this.edit) {
            this.$api.business.rideCard.update(this.formModel).then(callBack);
          } else {
            this.$api.business.rideCard.create(this.formModel).then(callBack);
          }
        }
      });
    }
  },
  beforeMount() {
    if (this.$route.query.id) {
      this.edit = true;
      const loading = ElLoading.service();
      this.$api.business.rideCard.detail(this.$route.query.id).then(res => {
        if (res.code === 200) {
          this.formModel = res.data;
        } else if (res.code === 121601) {
          this.$router.replace({
            name: '404'
          });
        } else {
          ElMessage.error(res.message);
          this.$router.back();
        }
      }).finally(() => {
        loading.close();
      });
    }
  }
};